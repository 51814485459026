import Fuse from 'fuse.js'
import csvDataRaw from 'bundle-text:./offers3.csv';
import { CSV } from './csv';

let params = (new URL(window.location.href)).searchParams;
export let currency = 'MXN';

// console.log(csvDataRaw);


export interface Offer {
    incoterm: any;
    search_text: string;
    request_data: string;
    request_date: string;
    printed_offer: string;
    offer_date: string;
    offer_number: string;
    contact: string;
    customer_number: string;
    price_group: string;
    customer_name: string;
    shipping: string;
    currency: string;
    payment_terms: string;
    items: Product[];
}

export interface Product {
    lead_time: any;
    moq: any;
    price_unit: any;
    id: string;
    offer_number: string;
    sku: string;
    prices: { quantity: number, price: number }[];
    country_of_origin: any;
    incoterm: any;
    alternative: string;
    quantity: string;
    customer_article_number: string;
    delivery_time: string;
    unit: string;
    position_type: string;
    discount: string;
    search_name: string;
    description: string;
    price: number;
    article_type: string;
    article_number: string;
    origin_country: string;
    goods_number: string;
    currency: string;
}

let csvData = csvDataRaw
    .replace(/An02Lieferzeit/g, 'lead_time')
    .replace(/At01MinBestellmenge/g, 'moq')
    .replace(/At01Verpackungseinh/g, 'packaging')
    .replace(/At01MengenSchl/g, 'price_unit')
    .replace(/An01AnfrageDaten/g, 'request_data')
    .replace(/An01AnfrageDatum/g, 'request_date')
    .replace(/An01AngebotGedruckt/g, 'printed_offer')
    .replace(/An01AngebotsDatum/g, 'offer_date')
    .replace(/An01AngebotsNr/g, 'offer_number')
    .replace(/An01Ansprechpartner/g, 'contact')
    //.replace(/An01Gueltigkeit/g, 'validity')
    .replace(/An01Incoterm/g, 'incoterm')
    .replace(/An01KundenNr/g, 'customer_number')
    .replace(/An01PreisGrp/g, 'price_group')
    .replace(/An01Suchname/g, 'customer_name')
    //.replace(/An01Verpackung/g, 'packaging')
    .replace(/An01Versand/g, 'shipping')
    .replace(/An01Waehrung/g, 'currency')
    .replace(/Zb01Bezeichnung/g, 'payment_terms')
    .replace(/An02Alternativ/g, 'alternative')
    .replace(/An02AngebotsMenge/g, 'quantity')
    .replace(/An02Bezeichnung1/g, 'sku')
    .replace(/An02KundenArtikelNr/g, 'customer_article_number')
    .replace(/An02Lieferzeit/g, 'delivery_time')
    .replace(/An02MengenBez/g, 'unit')
    .replace(/An02PosArt/g, 'position_type')
    .replace(/An02Rabatt/g, 'discount')
    .replace(/An02Suchname/g, 'search_name')
    .replace(/An02Text/g, 'description')
    .replace(/An02VkPreis/g, 'price')
    .replace(/At01ArtikelArt/g, 'article_type')
    .replace(/At01ArtikelNr/g, 'article_number')
    //.replace(/At01UrsprungLand/g, 'country_of_origin')
    .replace(/At01WarenNr/g, 'goods_number')
    .replace(/Ld01Bezeichnung/g, 'country_of_origin')
    //.replace(/Zb01Bezeichnung/g, 'description')
    .replace(/\r/g, '');


function getNeighbor(data: string, i: number, dir: number, log = false): [string, number] {
    if (log) console.log("LOG", data[i]);
    i += dir;
    let char = data[i];
    while (true) {
        char = data[i];
        if (char == '\n' || char == '\r' || char == '\t' || char == ' ') {
            i += dir;
            continue;
        }
        if (char == '"') {
            const [neighbor, newIndex] = getNeighbor(data, i, dir, log);
            if (log) {
                console.log('found1', char, i, newIndex, neighbor);
            }
            if (neighbor == '"') {
                return ["''", i];
            }
            if (neighbor == ';') {
                return ["''", i];
                const [nextneighbor] = getNeighbor(data, newIndex, dir, log);
                if (log) {
                    console.log('found2', char, i, newIndex, neighbor, nextneighbor);
                }
                if (nextneighbor != '"' && nextneighbor != ';') {
                    return ["''", i];
                }
                /*const [neighbor] = getNeighbor(data, newIndex, dir);
                if (neighbor !== '"') {
                    return ["''", i];
                }*/
            }
        }

        if (false && char == ';') {
            const [nextneighbor] = getNeighbor(data, i, dir, log);
            if (nextneighbor != '"' && nextneighbor != ';' && nextneighbor != undefined) {
                return ["''", i];
            }
            /*const [neighbor] = getNeighbor(data, newIndex, dir);
            if (neighbor !== '"') {
                return ["''", i];
            }*/
        }


        if (log) console.log("LOG", char);

        return [char, i];
    }
}

csvData = csvData.split('').map((it, i) => {

    // const log = csvData[i - 1] == '2' && csvData[i - 2] == '/' && csvData[i - 3] == '1';
    const [previous] = getNeighbor(csvData, i, -1);

    const [next] = getNeighbor(csvData, i, 1);
    /*if (log) {
        console.log("PREVNEXT", previous, next);
    }*/
    if (it == '"' && !(previous == ';' || previous == '"' || previous == undefined || next == ';' || next == '"' || next == undefined)) {
        //   console.log('replacing', i, it, previous, next);
        return "''";
    }
    return it;
}).join('');

//console.log("AFTER", csvData)

const lines = CSV.csvToObject(csvData, { trim: true, delimeter: ';' });

// console.log(lines);

export const offers = lines
    .filter(it => it.description)
    .reduce((acc: any, line: any) => {

        if (!acc[line.offer_number]) {
            acc[line.offer_number] = {
                ...line,
                items: []
            };
        }
        acc[line.offer_number].items.push(line);
        return acc;
    }, {});

Object.keys(offers).forEach((key) => {
    offers[key].items = Object.values(offers[key].items.reduce((acc: any, line: any) => {
        const id = getUniqueId(line);
        line.id = id;
        if (!acc[id]) {
            acc[id] = {
                ...line,
                prices: []
            };
        }
        acc[id].prices.push({ quantity: line.quantity, price: parseFloat(line.price?.replace('.', '')?.replace(',', '.')) });
        return acc;
    }, {}));
});

export const OffersList: Offer[] = Object.values(offers);
OffersList.forEach(it => it.search_text = it.items.map(it => [it.offer_number, it.sku, it.search_name, it.description, it.sku, it.article_number, it.customer_article_number].join(' ')).join(' ').toLowerCase());

console.log(OffersList);
export const productsList = [];/* CSV.csvToObject(csvData).map((product: any) => {
    return {
        sku: product['Supplier Part ID'].toString(),
        image: '/assets/images/' + product['Supplier Part ID'].toString().replace('#', '%23') + '.jpg.jpg',
        name: product['Item Description'].toString(),
        description: product['Item Description'].toString(),
        price: parseFloat(product['Unit Price'].toString()),
        category: [
            product.Group,
        ].filter(it => it).join('/'),
        unit: (product.unit || 'EA').toUpperCase(),
        unspsc: product['Classification Codes-1']?.toString(),
        currency: currency,
        data: product,
    }
}).filter(it => it.price > 0);*/


export function currencySymbol(currency: string) {
    return { 'USD': '$', 'EUR': '€', 'MXN': 'MX$' }[currency] || currency;
}

export function getCartItemPrice(item: CartItem) {
    return getItemPrice(item.product, item.quantity) || 0;
}

export interface CartItem {
    product: Product,
    quantity: number,
};

export class Cart {
    items: CartItem[] = [];
}

export class Model {

    cartClass = '';
    search: string = '';
    filter: any = {};

    products: Product[] = productsList;


    cart = new Cart();



    constructor() {
        if (localStorage.getItem('cart')) {
            this.cart = JSON.parse(localStorage.getItem('cart') as any);
        }
    }




}


export type Action
    = { type: 'add', product: Product, quantity: number }
    | { type: 'remove', product: Product }
    | { type: 'quantity', id: string, quantity: number }
    | { type: 'products', products: Product[] }
    | { type: 'filter', filter: any }
    | { type: 'filter.in', key: string, option: string, value: boolean }
    | { type: 'search', value: string }
    | { type: 'filter.clear' }
    | { type: 'cart.clear' }
    | { type: 'endanimate' };


let fuse = new Fuse<Product>([]);
//let fuseOffers = new Fuse<Offer>(OffersList, { keys: ['search_text', 'sku', 'customer_article_number', 'customer_name', 'offer_number'], findAllMatches: true, threshold: 0.3 });

export function RootReducer(state: Model, action: Action) {
    console.log(action);
    switch (action.type) {
        case 'cart.clear':
            state.cart.items = [];
            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'filter.clear':
            state.search = '';
            state.filter = {};
            break;
        case 'search':
            state.search = action.value;
            break;
        case 'add':
            const item = state.cart.items.find(it => it.product.id == action.product.id);
            if (item) {
                item.quantity += action.quantity;
            } else {
                const newCartItem = { product: action.product, quantity: action.quantity };
                state.cart.items.push(newCartItem);
            }
            state.cartClass = 'animate__animated animate__headShake';

            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'quantity':
            // update quantity
            state.cart.items.filter(it => it.product.id == action.id).forEach(it => {
                it.quantity = action.quantity;
            });
            // remove if quantity is 0
            state.cart.items = state.cart.items.filter(it => it.quantity);

            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'products':
            state.products = [...action.products];
            fuse = new Fuse(state.products, { keys: ['name', 'sku', 'description'], findAllMatches: true, threshold: 0.4 });
            break;
        case 'filter':
            state.filter = action.filter;
            break;
        case 'filter.in':
            state.filter[action.key] = state.filter[action.key] || [];
            if (action.value) {
                state.filter[action.key].push(action.option);
            } else {
                state.filter[action.key] = state.filter[action.key].filter(it => it !== action.option);
            }
            if (state.filter[action.key].length == 0) {
                delete state.filter[action.key];
            }
            break;
        case 'endanimate':
            state.cartClass = '';
            break;
        default:
            break;
    }
    return { ...state };
}


export function getItemPrice(item: Product, quantity: number) {
    if (item.prices.length == 0) return null;
    if (item.prices.length == 1) {
        return item.prices[0].price
    }
    const prices = item.prices.filter(it => it.quantity <= (quantity || 0));
    return prices.pop()?.price;
}

export function getMOQ(item: Product): number {
    if (item.prices.length == 0) return 0;
    if (item.moq) return parseInt(item.moq.toString());
    return parseInt(item.prices[0].quantity.toString());
}


export function search(state: Model) {

    let products = state.products;

    if (state.search) {
        products = fuse.search(state.search).map(it => it.item);
    }

    return products.filter(product => {
        for (const key in state.filter) {
            const value = state.filter[key];
            if (value == undefined) {
                continue;
            } else if (Array.isArray(value)) {
                if (value.includes(product[key])) {
                    continue;
                }
            } else if (value == product[key]) {
                continue;
            }
            return false;
        }
        return true;
    });
}


export function searchOffers(state: Model) {

    let products = OffersList;

    if (state.search) {
        const q = state.search.toLowerCase();
        products = OffersList.filter(it => it.search_text.includes(q));  //  fuseOffers.search(state.search).map(it => it.item);
    }

    return products;

    return products.filter(product => {
        for (const key in state.filter) {
            const value = state.filter[key];
            if (value == undefined) {
                continue;
            } else if (Array.isArray(value)) {
                if (value.includes(product[key])) {
                    continue;
                }
            } else if (value == product[key]) {
                continue;
            }
            return false;
        }
        return true;
    });
}

const hash = (str: any) => {
    if (typeof (str) !== 'string') {
        str = JSON.stringify(str);
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
};


export function getUniqueId(item: Product) {
    if (item.article_number == "DIVERS") {
        return item.offer_number + '-' + item.article_number + '-' + item.description;
    } else {
        return item.offer_number + '-' + item.article_number;
    }
}