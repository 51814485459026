var rxIsInt = /^\d+$/,
    rxIsFloat = /^\d*\.\d+$|^\d+\.\d*$/,
    // If a string has leading or trailing space,
    // contains a comma double quote or a newline
    // it needs to be quoted in CSV output
    rxNeedsQuoting = /^\s|\s$|,|"|\n/,

    isNumber = function (o) {
        return Object.prototype.toString.apply(o) === '[object Number]';
    },

    isString = function (o) {
        return Object.prototype.toString.apply(o) === '[object String]';
    },

    chomp = function (s) {
        if (s.charAt(s.length - 1) !== "\n") {
            // Does not end with \n, just return string
            return s;
        }
        // Remove the \n
        return s.substring(0, s.length - 1);
    },

    prepField = function (field) {
        if (isString(field)) {
            // Escape any " with double " ("")
            field = field.replace(/"/g, '""');

            // If the field starts or ends with whitespace, contains " or , or a newline or
            // is a string representing a number, quote it.
            if (rxNeedsQuoting.test(field) || rxIsInt.test(field) || rxIsFloat.test(field)) {
                field = '"' + field + '"';
                // quote empty strings
            } else if (field === "") {
                field = '""';
            }
        } else if (isNumber(field)) {
            field = field.toString(10);
        } else if (field === null || field === undefined) {
            field = '';
        } else {
            field = field.toString();
        }
        return field;
    };

export const CSV = {
    /**
     Converts an array into a Comma Separated Values string.
     Each item in the array should be an array that represents one row in the CSV.
     Nulls and undefined values are interpreted as empty fields.
  
     @method arrayToCsv
     @param {String} a The array to convert
  
     @returns {String} A CSV representation of the provided array.
     @for CSV
     @public
     @static
     @example
         var csv,
           books = [
             ['JavaScript: The Good Parts', 'Crockford, Douglas', 2008],
             ['Object-Oriented JavaScript', 'Stefanov, Stoyan', 2008],
             ['Effective JavaScript', 'Herman, David', 2012]
           ];
  
         csv = CSV.arrayToCsv(books);
  
         // csv now contains:
         //
         // JavaScript: The Good Parts,"Crockford, Douglas",2008\n
         // Object-Oriented JavaScript,"Stefanov, Stoyan",2008\n
         // Effective JavaScript,"Herman, David",2012\n
    */
    arrayToCsv: function (a) {
        var cur,
            out = '',
            row,
            i,
            j;

        for (i = 0; i < a.length; i += 1) {
            row = a[i];
            for (j = 0; j < row.length; j += 1) {
                cur = row[j];

                cur = prepField(cur);

                out += j < row.length - 1 ? cur + ',' : cur;
            }
            // End record
            out += "\n";
        }

        return out;
    },

    /**
      Converts a Comma Separated Values string into a multi-dimensional array.
      Each row in the CSV becomes an array.
      Empty fields are converted to nulls and non-quoted numbers are converted to integers or floats.
  
      @method csvToArray
      @return {Array} The CSV parsed as an array
      @param {String} s The string to convert
      @param {Object} [config] Object literal with extra configuration. For historical reasons setting config to `true` is the same as passing `{trim: true}`, but this usage is deprecated and will likely be removed in the next version.
      @param {Boolean} [config.trim=false] If set to True leading and trailing whitespace is stripped off of each non-quoted field as it is imported
      @for CSV
      @static
      @example
          var books,
            csv = 'JavaScript: The Good Parts,"Crockford, Douglas",2008\n' +
              'Object-Oriented JavaScript,"Stefanov, Stoyan",2008\n' +
              'Effective JavaScript,"Herman, David",2012\n';
  
          books = CSV.csvToArray(csv);
  
          // books now equals:
          // [
          //   ['JavaScript: The Good Parts', 'Crockford, Douglas', 2008],
          //   ['Object-Oriented JavaScript', 'Stefanov, Stoyan', 2008],
          //   ['Effective JavaScript', 'Herman, David', 2012]
          // ];
    */
    csvToArray: function (s, config, delimeter) {
        // Get rid of any trailing \n
        s = chomp(s);

        if (config === true) {
            config = {
                trim: true
            };
        } else {
            config = config || {};
        }

        var cur = '', // The character we are currently processing.
            inQuote = false,
            fieldQuoted = false,
            field = '', // Buffer for building up the current field
            row: string[] = [],
            out: string[][] = [],
            trimIt = config.trim === true ? true : false,
            i,
            processField = function (field) {
                var trimmedField = field.trim();
                if (fieldQuoted !== true) {
                    // If field is empty set to null
                    if (field === '') {
                        field = null;
                        // If the field was not quoted and we are trimming fields, trim it
                    } else if (trimIt === true) {
                        field = trimmedField;
                    }

                    // Convert unquoted numbers to numbers
                    if (rxIsInt.test(trimmedField) || rxIsFloat.test(trimmedField)) {
                        field = +trimmedField;
                    }
                }
                return field;
            };
        delimeter = delimeter || config.delimeter || ',';
        for (i = 0; i < s.length; i += 1) {
            cur = s.charAt(i);

            // If we are at a EOF or EOR
            if (inQuote === false && (cur === delimeter || cur === "\n")) {
                field = processField(field);
                // Add the current field to the current row
                row.push(field);
                // If this is EOR append row to output and flush row
                if (cur === "\n") {
                    out.push(row);
                    row = [];
                }
                // Flush the field buffer
                field = '';
                fieldQuoted = false;
            } else {
                // If it's not a ", add it to the field buffer
                if (cur !== '"') {
                    field += cur;
                } else {
                    if (!inQuote) {
                        // We are not in a quote, start a quote
                        inQuote = true;
                        fieldQuoted = true;
                    } else {
                        // Next char is ", this is an escaped "
                        if (s.charAt(i + 1) === '"') {
                            field += '"';
                            // Skip the next char
                            i += 1;
                        } else {
                            // It's not escaping, so end quote
                            inQuote = false;
                        }
                    }
                }
            }
        }

        // Add the last field
        field = processField(field);
        row.push(field);
        out.push(row);

        return out;
    },
    /**
      Converts a Comma Separated Values string into an array of objects.
      Each row in the CSV becomes an object with properties named after each column.
      Empty fields are converted to nulls and non-quoted numbers are converted to integers or floats.
  
      @method csvToObject
      @since 1.2.0
      @return {Array} The CSV parsed as an array of objects
      @param {String} s The string containing CSV data to convert
      @param {Object} config Object literal with extra configuration
      @param {Array} [config.columns] An array containing the name of each column in the CSV data. If not
        provided, the first row of the CSV data is assumed to contain the column names.
      @param {Boolean} [config.trim] If true any field parsed from the CSV data will have leading and
                                     trailing whitespace trimmed
      @for CSV
      @static
      @example
          var books,
            csv = 'title,author,year\n' +
              'JavaScript: The Good Parts,"Crockford, Douglas",2008\n' +
              'Object-Oriented JavaScript,"Stefanov, Stoyan",2008\n' +
              'Effective JavaScript,"Herman, David",2012\n';
  
          books = CSV.csvToObject(csv);
  
          // books now equals:
          // [
          //   {
          //     title: 'JavaScript: The Good Parts',
          //     author: 'Crockford, Douglas',
          //     year: 2008
          //   },
          //   {
          //     title: 'Object-Oriented JavaScript',
          //     author: 'Stefanov, Stoyan',
          //     year: 2008
          //   },
          //   {
          //     title: 'Effective JavaScript',
          //     author: 'Herman, David',
          //     year: 2012
          //   }
          // ];
    */
    csvToObject: function (s, config?) {
        config = config !== undefined ? config : {};
        var columns = config.columns,
            trimIt = !!config.trim,
            csvArray = this.csvToArray(s, trimIt, config.delimeter);

        // if columns were not provided, assume they are
        // in the first row
        if (!columns) {
            columns = csvArray.shift();
        }

        columns = columns.map(it => it.replace('"', '').trim());

        return csvArray.map(function (row) {
            var obj = {},
                i = 0,
                len = columns.length;
            for (; i < len; i += 1) {
                obj[columns[i]] = row[i];
            }
            return obj;
        });
    },

    /**
      Converts an array of objects into Comma Separated Values data
      Each propery on the objects becomes a column in the CSV data.
  
      @method objectToCsv
      @since 1.2.0
      @return {String} CSV data, each row representing an object from the input array, each field representing a property from those objects
      @param {String} arr An array of objects to be converted into CSV
      @param {Object} config Object literal with extra configuration
      @param {Array} [config.columns] An array containing the name of each column in the CSV data. If not
        provided, the column names will be inferred from the property names of the objects. Explicitly
        defining column names has several advantages:
  
        * It is faster since all column names are already known.
        * It allows you to specify a subset of the properties to use if you wish to.
        * It allows you to control what order the columns are output in, since the `for...in` statement used to infer field names does not guarantee a specific order.
  
      @param {Boolean} [config.includeColumns=true] By default `objectToCsv` outputs the column names as
        the first row of the CSV data. Set to false to prevent this.
      @for CSV
      @static
      @example
          var csv,
            books = [
              {
                title: 'JavaScript: The Good Parts',
                author: 'Crockford, Douglas',
                year: 2008
              },
              {
                title: 'Object-Oriented JavaScript',
                author: 'Stefanov, Stoyan',
                year: 2008
              },
              {
                title: 'Effective JavaScript',
                author: 'Herman, David',
                year: 2012
              }
            ];
  
          csv = CSV.objectToCsv(books);
  
          // csv now contains:
          //
          // title,author,year\n
          // JavaScript: The Good Parts,"Crockford, Douglas",2008\n
          // Object-Oriented JavaScript,"Stefanov, Stoyan",2008\n
          // Effective JavaScript,"Herman, David",2012\n
    */
    objectToCsv: function (arr, config) {
        config = config !== undefined ? config : {};
        var columns = config.columns,
            includeColumns = config.includeColumns,
            csv = '',
            csvColumns = '',
            processKnownColumns = function (obj?) {
                var out = '',
                    prop,
                    i,
                    len = arr.length,
                    j,
                    jlen = columns.length;

                for (i = 0; i < len; i += 1) {
                    obj = arr[i];
                    for (j = 0; j < jlen; j += 1) {
                        prop = columns[j];
                        out += prepField(obj[prop]);
                        out += j < jlen - 1 ? ',' : '';
                    }
                    out += '\n';
                }
                return out;
            },
            processUnknownColumns = function () {
                var cols: string[] = [],
                    firstRowLength,
                    finalRowLength,
                    obj,
                    prop,
                    i,
                    currentCol,
                    len = arr.length,
                    row: string[],
                    out: string[][] = [];

                for (i = 0; i < len; i += 1) {
                    obj = arr[i];
                    row = [];

                    // loop over all props in obj,
                    for (prop in obj) {
                        if (obj.hasOwnProperty(prop)) {
                            currentCol = cols.indexOf(prop);
                            // if this prop does not have a column yet
                            if (currentCol === -1) {
                                currentCol = cols.push(prop);
                                currentCol -= 1;
                            }
                            row[currentCol] = prepField(obj[prop]);
                        }
                    }

                    if (i === 0) {
                        firstRowLength = row.length;
                    }

                    out.push(row);
                }

                finalRowLength = cols.length;

                // if some objects had properties that weren't on all the object
                // we need to resize each row.
                if (firstRowLength !== finalRowLength) {
                    out.forEach(function (row) {
                        row.length = finalRowLength;
                    });
                }

                // export cols to our parent scope so
                // includeColumns can use it
                columns = cols;

                return out.map(function (row) {
                    return row.join(',');
                }).join('\n') + '\n';
            };

        includeColumns = includeColumns === undefined ? true : !!includeColumns;

        if (columns !== undefined) {
            csv = processKnownColumns();
        } else {
            csv = processUnknownColumns();
        }

        if (includeColumns) {
            columns.forEach(function (col) {
                csvColumns += prepField(col) + ',';
            });
            csvColumns = csvColumns.substring(0, csvColumns.length - 1);
            csv = csvColumns + '\n' + csv;
        }

        return csv;
    }
};
